.App {
  text-align: center;
  background-color: #7fa172c5;
}

.about{
  background-color: rgba(248, 239, 239, 0.513);
  border-radius: .5rem;
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
  margin: 3rem;
  padding: .5rem;
  font-size: 2rem;
}

.aboutMeLink {
  padding: .5rem;
  color: rgb(101, 206, 205);
}


.blurb{
  background: url('./img/header.jpeg') no-repeat center center fixed; 
  margin: 0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  text-align: center;
  resize: none;
  padding-top: 1rem;
  padding-bottom: 23vh;
}

.description{
  font-family: 'Source Sans Pro', sans-serif;
  text-align: left;
  margin: 3rem;
  padding: .5rem;
  font-size: 2rem;
  border-top: 1px solid black;
}

/*  for full screen. 
.carousel .carousel-item {
  width: 40%;
  height: 80%;
} */

.carousel .carousel-item {
  width: 80%;
  height: 100%;
}

.header{
  font-family: 'Merriweather', serif;
  text-align: center;
}

.icons {
  display: flex;
  justify-content: center;
  padding: 5rem;
}

.list-head {
  padding-bottom: vh;
}

.link {
  margin-top: .5rem;
  padding: .5rem;
  color: rgb(59, 119, 199);
}

.projectName{
  font-family: 'Merriweather', serif;
  text-align: left;
  padding-left: 2.5rem;
}

.spacer {
  height: 2rem;
  width: 100%;
}

.skills {
  display: flex;
  flex-direction: row;
  column-count: 2;
}

.skill-col {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 70%;
  padding-top: 1rem;
}

